.house-card-container {
    margin-bottom: 8rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 4px;
}

.house-card-image-placeholder {
    width: 18rem;
    border-radius: 4px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
}

.house-card-info-container {
    padding: 0 1rem 2rem;
}

.house-card-plan-price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3rem 0;
}

.house-card-plan,
.house-card-price {
    font-size: 1.2rem;
    font-weight: 600;
}

.house-card-plan {
    text-transform: uppercase;
    font-size: 1.35rem;
    letter-spacing: 1px;
    color: #d3322a;
}

.house-card-price {}

.house-card-square-feet-bed-bath-container {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0 0;
}

.house-card-square-feet-bed-bath-item {
    text-align: center;
}

.house-card-square-feet-bed-bath-item-logo {
    width: 1.5rem;
}

.house-card-square-feet-bed-bath-item-statement {
    font-size: .8rem;
    font-weight: bold;
}

.house-card-view-more-btn {
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    border: none;
    border-radius: 4px;
    padding: 1rem 2rem;
    width: 100%;
    color: #fff;
    background-color: #d3322a;
    margin: 2rem 0 0;
    text-transform: uppercase;
}

.house-card-view-more-btn:hover {
    cursor: pointer;
}

@media (max-width: 2150px) {
    .house-card-container {
        width: 45%;
    }

    .house-card-image-placeholder {
        width: 100%;
    }
}

@media (max-width: 1250px) {
    .house-card-container {
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .house-card-container {
        margin-bottom: 12rem;
    }

    .house-card-container:last-child {
        margin-bottom: 0;
    }
}

@media (max-width: 560px) {
    .house-card-container {
        margin: 0 auto 10rem;
        width: 85%;
    }
}
