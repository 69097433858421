.footer-container {
    padding: 6rem 26rem;
    display: flex;
}

.footer-section-container {
    margin-right: 9rem;
}

.footer-section-title {
    text-transform: uppercase;
    font-size: .8rem;
    color: #828282;
    font-weight: bolder;
    margin-bottom: 2rem;
}

.footer-section-list {
    list-style-type: none;
}

.footer-section-list-item {
    margin-bottom: 1rem;
}

a {
    text-decoration: none;
}

.footer-social-media-list {
    display: flex;
}

.footer-section-social-media-icon {
    width: 2rem;
    margin-right: 1rem;
}

@media (max-width: 2150px) {
    .footer-container {
        padding: 6rem 16rem;
    }
}

@media (max-width: 1775px) {
    .footer-container {
        padding: 6rem 10rem;
    }
}

@media (max-width: 1700px) {
    .footer-container {
        padding: 6rem 6rem;
    }
}

@media (max-width: 450px) {
    .footer-container {
        flex-direction: column;
    }
}

@media (max-width: 560px) {
    .footer-container {
        padding: 3rem;
    }

    .footer-section-container {
        margin-bottom: 3rem;
        width: 100%;
    }
}
