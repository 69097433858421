.home-plans-outer-container {
    margin: 3rem 0;
}

.home-plans-hero-title {
    padding: 0 2rem;
    font-family: 'Oswald', sans-serif;
    font-size: 2.5rem;
    margin: 0 0 1.5rem;
    color: #444;
}

.home-plans-hero-subtitle {
    font-family: 'Oswald', sans-serif;
    color: #d3322a;
}

.home-plans-hero-statement {
    font-size: 1.25rem;
    line-height: 1.7;
    padding: 0 2rem;
    margin: 0 0 3rem;
}
