.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    padding: 4rem 26rem;
}

.header-logo-placeholder {
    width: 9rem;
}

.header-nav-items {
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    display: flex;
    font-size: 1.1rem;
    list-style-type: none;
}

.nav-item {
    margin-right: 4rem;
    text-decoration: none;
}

.nav-item:last-child {
    margin-right: 0;
}

.nav-hamburger-icon {
    display: none;
}

.mobile-menu-container {
    display: none;
}

.mobile-menu-nav-container {
    display: flex;
    flex-direction: column;
}

.mobile-menu-nav-item {
    text-decoration: none;
}

@media (max-width: 2150px) {
    .header-container {
        padding: 4rem 12rem;
    }
}

@media (max-width: 1700px) {
    .header-container {
        padding: 4rem 4rem;
    }
}

@media (max-width: 1000px) {
    .header-nav-items {
        display: none;
    }

    .nav-hamburger-icon {
        display: block;
        width: 3rem;
    }

    .mobile-menu-container {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #d3322a;
        /* display: flex; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobile-menu-exit-icon {
        width: 4rem;
        margin-bottom: 5rem;
    }

    .mobile-menu-exit-icon:hover {
        cursor: pointer;
    }

    .mobile-menu-nav-container {
        font-family: 'Oswald', sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: uppercase;
        list-style-type: none;
    }

    .mobile-menu-nav-item {
        margin-bottom: 5rem;
        color: #fff;
        letter-spacing: 1px;
    }
}

@media (max-width: 450px) {
    .header-container {
        width: 100vw;
        padding: 4rem 1.5rem;
        margin: 0 auto;
    }

    .mobile-menu-exit-icon {
        width: 4rem;
    }

    .mobile-menu-nav-container {
        font-size: 1.5rem;
    }
}