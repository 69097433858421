.features-card-container {
    text-align: center;
    padding: 0 5rem;
}

.features-card-icon {
    width: 5rem;
    margin-bottom: 2rem;
}

.features-card-title {
    font-family: 'Oswald', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #d3322a;
    opacity: .85;
}

.features-card-statement {
    line-height: 1.8;
    font-weight: 400;
}

@media (max-width: 2150px) {
    .features-card-container {
        padding: 0 3.5rem;
    }
}

@media (max-width: 1050px) {
    .features-card-container {
        padding: 0 1.75rem;
    }
}

@media (max-width: 1000px) {
    .features-card-container {
        margin: 0 auto 9rem;
        width: 450px;
    }
}

@media (max-width: 700px) {
    .features-card-container {
        width: 80%;
    }
}

@media (max-width: 560px) {
    .features-card-container {
        width: 100%;
        padding: 0 3rem;
    }
}
