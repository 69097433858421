.home-plan-container {}

.home-plan-hero-container {
    padding: 0 2rem;
    margin: 2rem 0 0;
}

.home-plan-hero-title {
    font-family: 'Oswald', sans-serif;
    font-size: 5rem;
    font-weight: 700;
    color: #d3322a;
}

.home-plan-main-features-container {
    padding: 0 2rem;
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
}

.home-plan-main-features-feature {
    text-align: center;
}

.home-plan-main-features-feature-icon {
    width: 2rem;
}

.home-plan-main-features-feature-statement {
    margin: 1rem 0 0;
    font-weight: 700;
}

.home-plan-extra-features-container {
    padding: 0 2rem;
}

.home-plan-extra-features-title {
    font-family: 'Oswald', sans-serif;
    font-size: .9rem;
    font-weight: 700;
    color: #d3322a;
    letter-spacing: .6px;
    margin: 4rem 0 1rem;
}

.home-plan-extra-features-list {
    list-style-type: none;
}

.home-plan-extra-features-list-item {
    font-weight: 500;
    margin: 1rem 0;
}

.home-plan-photos-container {
    margin: 3rem 0;
    padding: 0 2rem;
}

.home-plan-photos-list {}

.home-plan-photo {
    width: 100%;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    margin: 1rem 0;
}


@media (min-width: 540px) {
    .home-plan-hero-container {
        padding: 0 4rem;
    }

    .home-plan-main-features-container {
        padding: 0 4rem;
        width: 62.5%;
    }

    .home-plan-extra-features-title {
        padding: 0 2rem;
    }

    .home-plan-extra-features-list {
        padding: 0 2rem;
    }

    .home-plan-photos-container {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 768px) {
    .home-plan-hero-container {
        padding: 0 6rem;
    }

    .home-plan-main-features-container {
        padding: 0 6rem;
        width: 62.5%;
    }

    .home-plan-extra-features-title {
        padding: 0 4rem;
    }

    .home-plan-extra-features-list {
        padding: 0 4rem;
    }

    .home-plan-photos-container {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1024px) {
    .home-plan-hero-container {
        text-align: center;
    }

    .home-plan-hero-title {
        font-size: 6rem;
    }

    .home-plan-main-features-container {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    .home-plan-extra-features-container {
        text-align: center;
    }

    .home-plan-extra-features-list {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .home-plan-extra-features-title {
        padding: 0 2rem;
    }

    .home-plan-extra-features-list {
        padding: 0 2rem;
    }

    .home-plan-photos-container {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1281px) {
    .home-plan-main-features-container {
        max-width: 720px;
    }

    .home-plan-extra-features-list {
        max-width: 540px;
    }

    .home-plan-photos-container {
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
    }

    .home-plan-photos-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .home-plan-photo {
        width: 45%;
        margin: 3rem 0;
    }
}