.contact-container {}

.contact-landing-hero-container {
    padding: 2.5rem 2rem 0;
}

.contact-landing-hero-title {
    color: #d3322a;
    font-size: 3rem;
    margin-bottom: 1rem;
}

.contact-landing-hero-statement {
    font-size: 1.1rem;
    line-height: 1.7;
}

.contact-options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5rem 0;
}

.contact-options-option-container {
    text-align: center;
    margin: 2.5rem 0;
}

.contact-options-option-icon {
    width: 3rem;
}

.contact-options-option-statement {
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
}

@media (min-width: 540px) {
    .contact-landing-hero-container {
        display: none;
    }
}

@media (min-width: 768px) {
    .contact-options-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 912px) {
    .contact-options-container {
        max-width: 500px;
    }
}
