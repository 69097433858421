@media (min-width: 1024px) {
    .available-homes-container {
        display: flex;
        flex-wrap: wrap;
    }
}

@media (min-width: 1260px) {
    .available-homes-container {
        max-width: 1260px;
        margin-left: auto;
        margin-right: auto;
    }
}