@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Oswald:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  color: #444;
}

.landing-page-hero-container {
  padding: 8rem 25rem;
  display: flex;
  justify-content: space-between;
}

.house-cards-container {
  width: 50%;
  height: 75vh;
  overflow: scroll;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem;
  text-align: end;
}

.house-cards-container::-webkit-scrollbar{
  display: none;
}

.landing-page-hero-welcome-container {
  /* background-color: beige; */
  width: 50%;
}

.landing-page-hero-title {
  font-family: 'Oswald', sans-serif;
  font-size: 6rem;
  margin-bottom: 2.5rem;
  color: #444;
}

.landing-page-hero-title-highlight {
  font-family: 'Oswald', sans-serif;
  color: #d3322a;
}

.landing-page-hero-statement {
  width: 75%;
  font-size: 1.5rem;
  line-height: 1.7;
  margin-bottom: 3rem;
  font-weight: 400;
}

.landing-page-build-with-us-btn {
  border: none;
  border-radius: 4px;
  background-color: #d3322a;
  color: #fff;
  padding: 1rem 2.5rem;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-family: 'Oswald', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.landing-page-build-with-us-btn:hover {
  cursor: pointer;
}

.features-cards-container {
  display: flex;
  justify-content: space-between;
  padding: 6rem 26rem;
}

.free-consultation-container {
  background-color: #d3322a;
  padding: 8rem 26rem;
  text-align: center;
}

.free-consultation-title {
  color: #fff;
  font-family: 'Oswald', sans-serif;
  font-size: 4rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.free-consultation-statement {
  color: #fff;
  width: 60%;
  margin: 0 auto;
  font-size: 1.25rem;
  line-height: 1.7;
}

.free-consultation-btn {
  border: none;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 1rem 3rem;
  background-color: #fff;
  color: #d3322a;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-family: 'Oswald', sans-serif;
  margin-top: 3rem;
  letter-spacing: 1px;
  word-spacing: 3.5px;
}

.builder-showcase-container {
  /* background-color: #d3322a; */
  display: flex;
  padding: 10rem 26rem;
}

.builder-showcase-container-left {}

.builder-showcase-container-left-statement {
  color: #d3322a;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.8;
  padding: 0 18rem 0 0;
}

.builder-showcase-container-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.builder-showcase-container-right-item {
  margin-right: 6rem;
}

.builder-showcase-container-right-item-title {
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
  margin-bottom: .75rem;
  color: #d3322a;
}

.builder-showcase-container-right-item-statement {
  /* white-space: nowrap; */
  font-weight: 500;
}

.builder-showcase-container-right-item:last-child {
  margin: 0;
}

.builder-guarantee-container {
  padding: 0 25rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12rem;
}

.builder-guarantee-image {
  width: 50%;
  margin-left: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 4px;
}

.builder-guarantee-message-container {
  width: 40%;
}

.builder-guarentee-message-title {
  font-family: 'Oswald', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #d3322a;
}

.builder-guarantee-message-statement {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 3.5rem;
}

.builder-guarantee-message-statement:last-child {
  margin-bottom: 0;
}

.get-started-today-container {}

.get-started-today-inner-container {
  background-color: #d3322a;
  margin: 0 26rem;
  padding: 3rem 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 16px;
  text-align: center;
}

.get-started-today-title {
  font-family: 'Oswald', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 1.35rem;
}

.get-started-today-statement {
  width: 50%;
  margin: 0 auto;
  color: #fff;
  line-height: 1.8;
}

.get-started-today-btn {
  font-family: 'Oswald', sans-serif;
  font-size: 1.1rem;
  border: none;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  text-transform: uppercase;
  padding: 1rem 3rem;
  margin-top: 3rem;
  background-color: #fff;
  color: #d3322a;
}

.get-started-today-btn:hover {
  cursor: pointer;
}

@media (max-width: 2150px) {
  .landing-page-hero-container {
    padding: 8rem 12rem;
  }

  .features-cards-container {
    padding: 6rem 12rem;
  }

  .builder-showcase-container {
    padding: 10rem 16rem;
  }

  .builder-guarantee-container {
    padding: 0 16rem 0 15rem;
  }

  .get-started-today-inner-container {
    margin: 0 16rem;
  }
}

@media (max-width: 1775px) {
  .landing-page-hero-container {
    padding: 8rem 10rem;
  }

  .features-cards-container {
    padding: 6rem 6rem;
  }

  .builder-showcase-container {
    padding: 10rem;
  }

  .builder-guarantee-container {
    padding: 0rem 9rem;
  }

  .get-started-today-inner-container {
    margin: 0 10rem;
  }

  .free-consultation-statement {
    width: 80%;
  }
}

@media (max-width: 1700px) {
  .landing-page-hero-container {
    padding: 8rem 4rem;
  }

  .features-cards-container {
    padding: 6rem 4rem;
  }

  .builder-showcase-container {
    padding: 6rem;
  }

  .builder-guarantee-container {
    padding: 0rem 5rem;
  }

  .get-started-today-inner-container {
    margin: 0 6rem;
  }

  .free-consultation-statement {
    width: 80%;
  }
}

@media (max-width: 1600px) {
  .free-consultation-statement {
    width: 115%;
    margin: 0 auto 0 -7.5%;
  }
}

@media (max-width: 1425px) {
  .free-consultation-statement {
    width: 130%;
    margin: 0 auto 0 -15%;
  }
}

@media (max-width: 1350px) {
  .builder-showcase-container-left-statement {
    width: 120%;
  }

  .get-started-today-statement {
    width: 65%;
  }
}

@media (max-width: 1250px) {
  .house-cards-container {
    width: 30%;
    margin-right: 10%;
  }

  .free-consultation-title {
    width: 150%;
    margin-left: -25%;
  }

  .free-consultation-statement {
    width: 175%;
    margin-left: -37.5%;
  }
}

@media (max-width: 1150px) {
  .house-cards-container {
    width: 35%;
    margin-right: 7.5%;
  }

  .free-consultation-btn {
    width: 125%;
    margin-left: -12.5%;
  }

  .builder-showcase-container-left-statement {
    width: 135%;
  }
}

@media (max-width: 1100px) {
  .landing-page-hero-title {
    font-size: 5rem;
  }

  .landing-page-hero-statement {
    font-size: 1.5rem;
  }

  .free-consultation-title {
    width: 200%;
    margin-left: -50%;
  }

  .free-consultation-statement {
    width: 250%;
    margin-left: -75%;
  }

  .builder-showcase-container-left-statement {
    display: none;
  }

  .builder-showcase-container-right{
    margin: 0 auto;
  }

  .builder-guarantee-image {
    display: none;
  }

  .builder-guarantee-message-container {
    width: 75%;
    text-align: center;
    margin: 0 auto;
  }
}

@media (max-width: 1050px) {
  .free-consultation-title {
    width: 300%;
    margin-left: -100%;
  }

  .free-consultation-statement {
    width: 400%;
    margin-left: -150%;
  }

  .free-consultation-btn {
    width: 200%;
    margin-left: -50%;
  }
}

@media (max-width: 1000px) {
  .landing-page-hero-container {
    flex-direction: column;
  } 

  .landing-page-hero-welcome-container {
    width: 100%;
  }

  .landing-page-hero-title {
    margin-top: 0;
    margin-bottom: 3rem;
    font-size: 6rem;
  }

  .landing-page-hero-statement {
    font-size: 1.75rem;
    width: 85%;
    margin-bottom: 4rem;
    line-height: 1.8;
  }

  .landing-page-build-with-us-btn {
    font-size: 1.1rem;
  }

  .house-cards-container {
    width: 75%;
    margin: 12rem auto 0;
    height: auto;
  }

  .features-cards-container {
    flex-direction: column;
  }

  .free-consultation-container {
    display: none;
  }

  .free-consultation-title {
    width: 700%;
    margin-left: -300%;
  }

  .free-consultation-statement {
    width: 700%;
    margin-left: -300%;
  }

  .free-consultation-btn {
    width: 500%;
    margin-left: -200%;
  }
}

@media (max-width: 800px) {
  .landing-page-hero-title {
    font-size: 4.35rem;
  }

  .landing-page-hero-statement {
    font-size: 1.5rem;
    margin-top: -5%;
  }

  .landing-page-build-with-us-btn {
    font-size: 1rem;
  }

  .free-consultation-container {
    display: none;
  }

  .builder-showcase-container {
    padding: 0 0 4rem;
  }

  .builder-showcase-container-right {
    display: flex;
    justify-content: space-between;
    min-width: 90vw;
  }

  .builder-showcase-container-right-item {
    text-align: center;
    margin: 0;
  }

  .builder-showcase-container-right-item-title {
    text-align: center;
  }
}

@media (max-width: 560px) {
  .landing-page-hero-container {
    padding: 0;
  }

  .landing-page-hero-welcome-container {
    padding: 0 2rem;
    margin-top: 9rem;
  }

  .landing-page-hero-title {
    font-size: 3rem;
    margin-top: -6rem;
  }

  .landing-page-hero-statement {
    /* display: none; */
    margin-top: -2rem;
    font-size: 1.1rem;
  }

  .landing-page-build-with-us-btn {
    margin-top: -2rem;
    font-size: 1rem;
  }

  .builder-showcase-container {
    padding: 0;
    text-align: center;
  }

  .builder-showcase-container-right {
    display: flex;
    flex-direction: column;
  }

  .builder-showcase-container-right-item {
    margin: 0 auto 4rem;
  }

  .builder-showcase-container-right-item:last-child {
    margin-bottom: 7rem;
  }

  .builder-guarantee-container {
    padding: 0 3rem;
  }

  .builder-guarantee-message-container {
    width: 100%;
  }

  .builder-guarentee-message-title {
    font-size: 2rem;
  }

  .builder-guarantee-message-statement {
    width: 100%;
    padding: 0;
  }

  .house-cards-container {
    width: 100%;
    padding: 0;
    padding-bottom: 3rem;
    margin-bottom: 10rem;
  }

  .features-cards-container {
    padding: 0;
  }

  .get-started-today-inner-container {
    padding: 3rem 2rem;
    margin: 0;
    width: 100%;
    border-radius: 0;
  }

  .get-started-today-title {
    font-size: 2rem;
  }

  .get-started-today-statement {
    padding: 0;
    width: 100%;
  }

  .get-started-today-btn {
    font-size: .9rem;
  }
}