.available-home-card-container {
    color: #444;
    padding: 2rem;
    margin: 2.5rem 0;
}

.available-home-card-image {
    width: 100%;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.available-home-card-address {
    font-weight: 700;
    margin: .5rem 0 1rem;
    font-size: .75rem;
}

.available-home-card-price-state-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    margin: 1rem 0 2.5rem;
}

.available-home-card-price-state-container-price {
    font-size: 1.4rem;
    color: #d3322a;
}

.available-home-card-price-state-container-state {
    color: #d3322a;
    font-size: .75rem;
    font-weight: 800;
    opacity: .9;
    text-transform: uppercase;
}

.available-home-card-features-container-left {}

.available-home-card-features-container-right {}

.available-home-card-features-container-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.available-home-card-features-container-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.available-home-card-features-container-list-item-icon {
    width: 1.5rem;
    margin-bottom: .5rem;
}

.available-home-card-features-container-list-item-icon-data {
    font-weight: 600;
}

.available-home-card-btn {
    border: none;
    border-radius: 4px;
    width: 100%;
    padding: 1rem 0;
    box-shadow: #828282 0px 4px 12px;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: #d3322a;
    color: #fff;
}

.available-home-card-btn:hover {
    cursor: pointer;
}

@media (min-width: 425px) {
    .available-home-card-container {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media (min-width: 525px) {
    .available-home-card-container {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

@media (min-width: 600px) {
    .available-home-card-container {
        padding-left: 6.25rem;
        padding-right: 6.25rem;
        margin-bottom: 6rem;
    }
}

@media (min-width: 700px) {
    .available-home-card-container {
        width: 75%;
        padding-left: 6.25rem;
        padding-right: 6.25rem;
        margin: 6rem auto 8rem;
    }
}

@media (min-width: 1024px) {
    .available-home-card-container {
        width: 40%;
        padding: 0;
    }
}
