.build-with-us-container {}

.build-with-us-hero-container {
    padding: 0 2rem;
    margin: 3rem 0;
}

.build-with-us-hero-title {
    font-family: 'Oswald', sans-serif;
    font-size: 2.5rem;
    margin: 0 0 1.5rem;
}

.build-with-us-hero-title-highlight {
    font-family: 'Oswald', sans-serif;
    color: #d3322a;
}

.build-with-us-hero-statement {
    font-size: 1.25rem;
    line-height: 1.7;
}

.builder-expertise-showcase-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.builder-expertise-showcase-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3.75rem 0;
}

.builder-expertise-showcase-icon {
    width: 4rem;
    margin: 0 0 1rem;
}

.builder-expertise-showcase-statement {
    font-weight: 700;
    text-transform: uppercase;
}

.more-information-container {
    background-color: #d3322a;
    padding: 3rem 2rem;
    text-align: center;
    margin-bottom: 6rem;
}

.more-information-title {
    color: #fff;
    font-family: 'Oswald', sans-serif;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.more-information-statement {
    color: #fff;
    font-size: 1.1rem;
    margin-bottom: 2rem;
    line-height: 1.7;
}

.more-information-btn {
    border: none;
    border-radius: 4px;
    padding: 1rem 3rem;
    color: #d3322a;
    background-color: #fff;
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 800;
}

.more-information-btn:hover {
    cursor: pointer;
}

@media (min-width: 520px) {
    .build-with-us-hero-title {
        font-size: 3.5rem;
    }

    .build-with-us-hero-statement {
        padding-right: 4rem;
    }
}

@media (min-width: 625px) {
    .more-information-statement {
        max-width: 585px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 768px) {
    .build-with-us-hero-statement {
        max-width: 625px;
    }

    .builder-expertise-showcase-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 2rem;
        justify-content: space-between;
    }

    .builder-expertise-showcase-item {
        width: 50%;
    }
}

@media (min-width: 820px) {
    .build-with-us-hero-container {
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

@media (min-width: 1024px) {
    .build-with-us-hero-container {
        padding-left: 8rem;
        padding-right: 8rem;
    }

    .build-with-us-hero-statement {
        width: 75%;
    }
}

@media (min-width: 1024px) {
    .build-with-us-hero-container {
        text-align: center;
    }

    .build-with-us-hero-statement {
        text-align: center;
        margin: 5% auto 0;
        padding: 0;
    }
}

@media (min-width: 1280px) {
    .build-with-us-inner-container {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .build-with-us-hero-container {
        text-align: center;
    }

    .build-with-us-hero-title {
        font-size: 6rem;
    }

    .build-with-us-hero-statement {
        font-size: 1.5rem;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }

    .builder-expertise-showcase-container {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }

    .more-information-container {
        border-radius: 12px;
        margin-top: 6rem;
    }

    .more-information-title {
        font-size: 3rem;
    }

    .more-information-statement {
        width: 62.5%;
        margin-left: auto;
        margin-right: auto;
    }
}
